import * as React from 'react';
import TermsLayout from '../../components/termspage/TermsLayout';
import { TermsError, TermsLoadingPage, TermsOutline } from '../../components';
import { privacyLinks } from '../../utilities/data';
import { purifyHTML } from '../../utilities/purify-html';
import { useStrapiStore } from '../../store/useStrapiStore';
import { removeSpacesFromString } from '../../utilities';

const PrivacyPage = () => {
  const { termsData, termsState } = useStrapiStore((state) => ({
    termsState: state.termsState,
    termsData: state.termsData,
  }));

  if (termsState === 'loading') {
    return <TermsLoadingPage links={privacyLinks} />;
  }

  if (termsState === 'error') {
    return <TermsError />;
  }

  const irorunPrivacy = termsData['irorun-privacy'];

  return (
    <TermsLayout>
      <div>
        <div className="content">
          <TermsOutline links={privacyLinks} />
          <div
            dangerouslySetInnerHTML={{
              __html: purifyHTML(
                removeSpacesFromString(irorunPrivacy.page_content)
              ),
            }}
          />
        </div>
      </div>
    </TermsLayout>
  );
};

export default PrivacyPage;
